<template>
  <v-card width="100%" class="fill-height pa-6">
    <v-alert border="top" type="warning" elevation="2" :data-cy="`${dataCy}-alert`" variant="text" class="mb-4">
      {{ $t(`${i18nGroupKey}.paymentAlert`) }}
    </v-alert>
    <ShoppingCartTable class="mb-4" @refresh-table="updateShoppingCart" />
    <v-row class="justify-space-between">
      <v-col cols="12" sm="auto" class="d-flex flex-grow-1 align-center pb-4 pb-sm-3">
        <v-row class="justify-space-between">
          <v-col cols="12" sm="auto" class="text-center text-sm-left pb-4 pb-sm-3">
            <span :data-cy="`${dataCy}-remainingTime`" class="font-weight-bold ml-4 ml-sm-0">
              {{ `Košík expiruje za: ${lifetime}` }}
            </span>
          </v-col>
          <v-col cols="12" sm="auto" class="text-center text-sm-right pt-0 pt-sm-3">
            <span :data-cy="`${dataCy}-totalPrice`" class="font-weight-bold ml-4 ml-sm-0">
              <v-icon color="black" icon="$shoppingCartIcon" />
              {{ `${$t('shoppingCart.toPay')}: ` }}
              {{ toPrice(shoppingCartModule.totalPrice.toString()) }}
              {{ $t('common.currency') }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="text-right d-flex pt-0 pt-sm-3" cols="auto" sm="auto">
        <span :title="cancelAllTitle">
          <v-btn
            :disabled="isCancelAllButtonDisabled"
            :theme="!isCancelAllButtonDisabled ? 'dark' : ''"
            class="mb-4 ml-4 ml-sm-0 rdp-button"
            :title="$t('shoppingCart.cancelAll')"
            color="buttonPrimary"
            :data-cy="`${dataCy}-cancel-all-btn`"
            @click="cancelAll"
          >
            <template #prepend>
              <v-icon size="large" icon="$cancelAllIcon" />
            </template>
            <span class="rdp-button-text">{{ $t('shoppingCart.cancelAll') }}</span>
          </v-btn>
          <span v-if="isPayByBankCardAllowed">
            <v-btn
              :disabled="!canPayByCard"
              :theme="canPayByCard ? 'dark' : ''"
              :title="$t('shoppingCart.payByCard')"
              class="mb-4 ml-4 rdp-button"
              color="buttonPrimary"
              :data-cy="`${dataCy}-payByCard-btn`"
              @click="payByCard"
            >
              <template #prepend>
                <v-icon size="large" icon="$payByCardIcon" />
              </template>
              <span class="rdp-button-text">{{ $t('shoppingCart.payByCard') }}</span>
            </v-btn>
          </span>
          <span>
            <v-btn
              v-if="isPayByInvoiceAllowed"
              :title="payByInvoiceTitle"
              :disabled="isPayByInvoiceButtonDisabled"
              :theme="!isPayByInvoiceButtonDisabled ? 'dark' : ''"
              color="buttonPrimary"
              class="mb-4 ml-4 rdp-button pay-by-invoice"
              :data-cy="`${dataCy}-payByInvoice-btn`"
              @click="payByInvoice"
            >
              <template #prepend>
                <v-icon size="large" icon="$payByInvoiceIcon" />
              </template>
              <span class="rdp-button-text">{{ $t('shoppingCart.payByInvoice') }}</span>
            </v-btn>
          </span>
        </span>
      </v-col>
    </v-row>
    <RDPConfirmDialog
      ref="cancelAllConfirmDialog"
      :title="$t('shoppingCart.cancelAllDialog.title')"
      :text="$t('shoppingCart.cancelAllDialog.text')"
      :confirm="confirmCancelAll"
      :data-cy="`${dataCy}-cancel-all-dialog`"
    />
  </v-card>
</template>

<script lang="ts">
import { computed } from 'vue';
import { Component, Ref } from 'vue-facing-decorator';
import ShoppingCartModule from '@/store/modules/shoppingCart';
import ShoppingCartTable from '@/components/shopingCart/ShoppingCartTable.vue';
import { RDPDialogInterface } from '@/components/common/RDPDialogInterface';
import eshopOrderService from '@/services/api/eshopOrderService';
import RDPConfirmDialog from '@/components/common/RDPConfirmDialog.vue';
import paymentService from '@/services/api/paymentService';
import ComponentBase from '@/components/common/ComponentBase';
import { getEarliestTicketFareValidFrom } from '@/utils/ticketFare';
import { formatDate } from '@/utils/dateTime';
import Paths from '@/constants/Paths';
import { isArrayEmpty } from '@common/utils/isDefined';
import cardApplicationService from '@/services/api/cardApplicationService';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import { PERMANENT_TOAST_INTERVAL } from '@/constants/Toast';
import { EnumsDto, GpCreateOrderResponse, PaymentStatus, UpdatePaymentStatusCommand } from '@/api';
import ToastModule from '@/store/modules/toast';
import { toPrice } from '@/filters';

@Component({
  components: { ShoppingCartTable, RDPConfirmDialog },
})
export default class ShoppingCartPage extends ComponentBase {
  @Ref()
  readonly cancelAllConfirmDialog!: RDPDialogInterface;

  shoppingCartModule = ShoppingCartModule;
  systemConfiguration = SystemConfigurationModule;
  payRunning = false;

  lifetime = computed(() => this.shoppingCartModule.remainingTime);

  dataCy = 'shopping-cart';
  i18nGroupKey = 'shoppingCart';

  async mounted() {
    await this.updateShoppingCart();
    let payId: number | string = '';
    let gpPaymentPayload;

    if (this.systemConfiguration.isCsobPaymentGateway) {
      payId = this.$router.currentRoute.value.query.payId as string;
    } else if (this.systemConfiguration.isGpPaymentGateway) {
      gpPaymentPayload = this.$router.currentRoute.value.query as unknown as GpCreateOrderResponse;
      payId = gpPaymentPayload?.ORDERNUMBER as number;
    }

    if (!payId) {
      return;
    }

    const payload: UpdatePaymentStatusCommand = {
      gpPaymentPayload,
    };

    const { paymentStatus } = await paymentService.updatePaymentStatus(payId, payload);
    const toastOptions = { timeout: PERMANENT_TOAST_INTERVAL };

    switch (paymentStatus) {
      case PaymentStatus.paymentStatus.PAID:
        ToastModule.success({
          message: this.$t(`${this.i18nGroupKey}.paymentSuccess`),
          options: toastOptions,
        });
        break;
      case PaymentStatus.paymentStatus.PENDING:
        ToastModule.success({
          message: this.$t(`${this.i18nGroupKey}.paymentPending`),
          options: toastOptions,
        });
        break;
      case PaymentStatus.paymentStatus.PROCESSING:
        ToastModule.success({
          message: this.$t(`${this.i18nGroupKey}.paymentProcessing`),
          options: toastOptions,
        });
        break;
      case PaymentStatus.paymentStatus.CANCELLED:
      case PaymentStatus.paymentStatus.REJECTED:
      case PaymentStatus.paymentStatus.RETURNED:
        ToastModule.error({
          message: this.$t(`${this.i18nGroupKey}.paymentError`),
          options: toastOptions,
        });
        break;
      default:
        ToastModule.error({
          message: this.$t(`${this.i18nGroupKey}.paymentFailed`),
          options: toastOptions,
        });
    }

    await this.$router.push(Paths.ORDER_LIST);
  }

  async updateShoppingCart() {
    const isCartExpired = await this.shoppingCartModule.isCartExpired();

    if (isCartExpired) {
      this.shoppingCartModule.nullLifetimeAndClearCart();
      return;
    }

    await this.shoppingCartModule.updateShoppingCart();

    if (this.shoppingCartModule.isEmpty) {
      await this.shoppingCartModule.nullCartLifetime();
    }
  }

  cancelAll() {
    this.cancelAllConfirmDialog.openDialog();
  }

  async confirmCancelAll() {
    try {
      this.rejectAllCardApplicationsInShoppingCart();
      await this.shoppingCartModule.nullLifetimeAndClearCart();
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.cancelAllDialog.success`),
      });
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.cardItemList.loadingFailed`),
      });
    }
  }

  private rejectAllCardApplicationsInShoppingCart() {
    const allCardApplications = this.shoppingCartModule.items.filter(item => item.payload?.applicationId);
    if (!isArrayEmpty(allCardApplications)) {
      allCardApplications.forEach(async cardApplications => {
        await cardApplicationService.rejectCardApplication(
          cardApplications.payload?.applicationId,
          this.t('customerCancelApplicationInEshop'),
        );
      });
    }
  }

  async payByCard() {
    this.payRunning = true;
    try {
      const order = await eshopOrderService.createOrder({
        paymentType: EnumsDto.paymentTypes.BANK_CARD,
      });
      await this.shoppingCartModule.updateShoppingCart();
      const payment = await eshopOrderService.initPayment(order.id);

      eshopOrderService.processedPayment(payment);
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.paymentFailed`),
      });
    } finally {
      this.payRunning = false;
    }
  }

  async payByInvoice() {
    this.payRunning = true;
    try {
      await eshopOrderService.createOrder({
        paymentType: EnumsDto.paymentTypes.INVOICE,
      });
      await this.shoppingCartModule.updateShoppingCart();
      ToastModule.success({
        message: this.$t(`${this.i18nGroupKey}.invoicePaymentWaiting`),
      });
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.paymentFailed`),
      });
    } finally {
      this.payRunning = false;
    }
  }

  get isCancelAllButtonDisabled() {
    return this.isShoppingCartEmpty || this.payRunning;
  }

  get cancelAllTitle() {
    return this.isShoppingCartEmpty || this.payRunning
      ? this.t('cartIsEmpty')
      : this.shoppingCartModule.isCardApplicationInShoppingCart
        ? this.t('cancelApplicationItemsOneByOne')
        : this.t('cancelAll');
  }

  get isPayByInvoiceButtonDisabled() {
    return (
      this.isShoppingCartEmpty ||
      this.shoppingCartModule.isCardApplicationInShoppingCart ||
      this.shoppingCartModule.isTicketFareTooSoon ||
      this.payRunning
    );
  }

  get isPayByInvoiceAllowed() {
    return this.systemConfiguration.allowedPaymentTypes.some(
      paymentType => paymentType.name === EnumsDto.paymentTypes.INVOICE,
    );
  }

  get isPayByBankCardAllowed() {
    return this.systemConfiguration.allowedPaymentTypes.some(
      paymentType => paymentType.name === EnumsDto.paymentTypes.BANK_CARD,
    );
  }

  get payByInvoiceTitle() {
    if (this.isShoppingCartEmpty) {
      return this.t('cartIsEmpty');
    }
    if (this.shoppingCartModule.isCardApplicationInShoppingCart) {
      return this.t('cannotPayCardApplicationByInvoice');
    }
    if (this.shoppingCartModule.isTicketFareTooSoon) {
      const earliestValidFrom = formatDate(getEarliestTicketFareValidFrom());
      return this.t('ticketFareIsTooSoon', { earliestValidFrom });
    }
    return this.t('payByInvoice');
  }

  get canPayByCard() {
    return !this.isShoppingCartEmpty && !this.payRunning;
  }

  get isShoppingCartEmpty() {
    return this.shoppingCartModule.isEmpty;
  }

  toPrice(value: string) {
    return toPrice(value);
  }
}
</script>
<style lang="scss" scoped>
.pay-by-invoice {
  svg {
    margin-left: 0 !important;
  }
}
</style>
