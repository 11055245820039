/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountActiveTicketListResponse } from '../models/AccountActiveTicketListResponse';
import type { AccountCartLifetimeResponse } from '../models/AccountCartLifetimeResponse';
import type { ApiResponse } from '../models/ApiResponse';
import type { AssignCustomerAccountLinkHeaderResponse } from '../models/AssignCustomerAccountLinkHeaderResponse';
import type { AssignCustomerToAccountCommand } from '../models/AssignCustomerToAccountCommand';
import type { CreateCustomerAccountResponse } from '../models/CreateCustomerAccountResponse';
import type { CreateEshopAccountForCustomerCommand } from '../models/CreateEshopAccountForCustomerCommand';
import type { CustomerAccountDetailResponse } from '../models/CustomerAccountDetailResponse';
import type { CustomerAccountListResponse } from '../models/CustomerAccountListResponse';
import type { CustomerAccountSettingResponse } from '../models/CustomerAccountSettingResponse';
import type { CustomerListResponse } from '../models/CustomerListResponse';
import type { UpdateCustomerAccountCommand } from '../models/UpdateCustomerAccountCommand';
import type { UpdateCustomerAccountSettingCommand } from '../models/UpdateCustomerAccountSettingCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CustomerAccountService {
  /**
   * Get card application.
   * @returns CustomerListResponse
   * @throws ApiError
   */
  public static customerAccountControllerGetAccountRelatedCustomers({
    adultOnly,
  }: {
    adultOnly?: boolean;
  }): CancelablePromise<CustomerListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/customer-accounts-related-customers',
      query: {
        adultOnly: adultOnly,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Update account setting.
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.
   * @returns CustomerAccountSettingResponse
   * @throws ApiError
   */
  public static customerAccountControllerUpdateSettings({
    mapPhoneAuthToken,
    requestBody,
  }: {
    mapPhoneAuthToken?: any;
    /**
     * UpdateCustomerAccountSettingCommand
     */
    requestBody?: UpdateCustomerAccountSettingCommand;
  }): CancelablePromise<CustomerAccountSettingResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/eshop/customer-accounts/settings',
      headers: {
        'map-phone-auth-token': mapPhoneAuthToken,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get active tickets from registered or assigned customers for account.
   * @returns AccountActiveTicketListResponse
   * @throws ApiError
   */
  public static customerAccountControllerGetAccountActiveTickets(): CancelablePromise<AccountActiveTicketListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/customer-accounts-active-tickets',
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Remove customer eshop account.
   * @returns ApiResponse
   * @throws ApiError
   */
  public static customerAccountControllerRemoveCustomerAccount(): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/eshop/customer-accounts',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get customer cart lifetime.
   * @returns AccountCartLifetimeResponse
   * @throws ApiError
   */
  public static customerAccountControllerGetCustomerCartLifeTime(): CancelablePromise<AccountCartLifetimeResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/eshop/customer-accounts/cart-lifetime',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Null customer cart lifetime.
   * @returns any Successful response
   * @throws ApiError
   */
  public static customerAccountControllerNullCustomerCartLifetime(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/eshop/customer-accounts/cart-lifetime-nullify',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get customer accounts...
   * Require: `PERMISSION_CUSTOMER-CARDS_EDIT`
   * @returns CustomerAccountListResponse
   * @throws ApiError
   */
  public static customerAccountControllerGetAll({
    userName,
    verified,
    timezoneOffset,
    limit,
    offset,
    sortBy,
    sortDesc,
    sortLocale,
  }: {
    userName?: string;
    verified?: boolean;
    timezoneOffset?: number;
    limit?: number;
    offset?: number;
    sortBy?: string;
    sortDesc?: boolean;
    sortLocale?: string;
  }): CancelablePromise<CustomerAccountListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/system/customer-accounts',
      query: {
        userName: userName,
        verified: verified,
        timezoneOffset: timezoneOffset,
        limit: limit,
        offset: offset,
        sortBy: sortBy,
        sortDesc: sortDesc,
        sortLocale: sortLocale,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Assign customer to new account
   * Require: `PERMISSION_CUSTOMER-CARDS_EDIT`
   * @returns AssignCustomerAccountLinkHeaderResponse
   * @throws ApiError
   */
  public static customerAccountControllerAssignCustomerToAccount({
    requestBody,
  }: {
    /**
     * AssignCustomerToAccountCommand
     */
    requestBody?: AssignCustomerToAccountCommand;
  }): CancelablePromise<AssignCustomerAccountLinkHeaderResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/system/customer-accounts',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Get customer account.
   * Require: `PERMISSION_ADMINISTRATION_VIEW`
   * @returns CustomerAccountDetailResponse
   * @throws ApiError
   */
  public static customerAccountControllerGetOne({
    id,
  }: {
    id: string;
  }): CancelablePromise<CustomerAccountDetailResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/system/customer-accounts/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Update customer account.
   * Require: `PERMISSION_ADMINISTRATION_VIEW`<br>
   * ### Error names for HTTP Status 400 BadRequest:<br>
   * + **VALIDATION_ERROR** - Invalid body, check 'errors' property for more info.<br>
   * + **INVALID_USERNAME** - Invalid username.<br>
   * + **USERNAME_NOT_UNIQUE** - Username already exists.
   * @returns CustomerAccountDetailResponse
   * @throws ApiError
   */
  public static customerAccountControllerUpdate({
    id,
    requestBody,
  }: {
    id: string;
    /**
     * UpdateCustomerAccountCommand
     */
    requestBody?: UpdateCustomerAccountCommand;
  }): CancelablePromise<CustomerAccountDetailResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/system/customer-accounts/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Delete customer account.
   * If an entity has any link, 409 Conflict will be returned.<br>
   * Require: `PERMISSION_ADMINISTRATION_VIEW`
   * @returns ApiResponse
   * @throws ApiError
   */
  public static customerAccountControllerDelete({ id }: { id: string }): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/system/customer-accounts/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        409: `Request couldn't be completed due to a conflict.`,
        500: `Something went wrong.`,
      },
    });
  }
  /**
   * Create eshop account for customer
   * Require: `PERMISSION_CUSTOMER-CARDS_EDIT`
   * @returns CreateCustomerAccountResponse
   * @throws ApiError
   */
  public static customerAccountControllerCreateEshopAccountForCustomer({
    requestBody,
  }: {
    /**
     * CreateEshopAccountForCustomerCommand
     */
    requestBody?: CreateEshopAccountForCustomerCommand;
  }): CancelablePromise<CreateCustomerAccountResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/system/customer-accounts/create-eshop-account',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User wasn't authenticated.`,
        403: `User didn't have permissions to perform this action.`,
        404: `Resource wasn't found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
