import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import router from '@/router';
import Paths from '@/constants/Paths';
import { CustomerProfileVm } from '@/api';
import { jwtDecode } from 'jwt-decode';
import UserAccount from '@/store/modules/userAccount';
import CardApplication from '@/store/modules/cardApplication';
import CustomerModule from '@/store/modules/customer';
import ShoppingCartModule from '@/store/modules/shoppingCart';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import { formatName } from '@/utils/customer.ts';
import { differenceInMilliseconds } from 'date-fns';

interface JwtInterface {
  customerAccountId: string;
  iat: number;
  exp: number;
}

@Module({
  dynamic: true,
  store,
  namespaced: true,
  name: 'auth',
  preserveState: localStorage.getItem('vuex') !== null,
})
class Auth extends VuexModule {
  public logged = false;
  public token = '';
  public customerAccountId = '';
  public username = '';
  public tokenExpiresIn: string | Date = '';
  private logoutWhenExpiredTimeout: NodeJS.Timeout | null = null;

  @Mutation
  public login(payload: CustomerProfileVm): void {
    CardApplication.initCardApplication();
    UserAccount.clearCardData();
    UserAccount.clearSelectedCustomerData();

    this.logged = true;
    this.token = payload.token;
    this.customerAccountId = payload.customerAccountId;
    this.username = payload.username;
    if (payload.customer) {
      CustomerModule.setCustomer(payload.customer);

      if (payload.customer.cartLifeTime) {
        ShoppingCartModule.setExpirationTime(payload.customer.cartLifeTime);
        ShoppingCartModule.setCartLifetimeTimer();
      }
    }

    const decodedJWT: JwtInterface = jwtDecode(payload.token);

    const actualDate = new Date(0);
    actualDate.setUTCSeconds(decodedJWT.exp);
    this.tokenExpiresIn = actualDate;

    const expirationDate = differenceInMilliseconds(actualDate, new Date());
    this.logoutWhenExpiredTimeout = setTimeout(() => {
      getModule(Auth).logout();
    }, expirationDate);
  }

  @Mutation
  public logout(): void {
    this.logged = false;
    this.token = '';
    this.customerAccountId = '';
    CustomerModule.clearLinkedCustomer();
    this.username = '';
    CustomerModule.clearCustomer();
    SystemConfigurationModule.clearSystemConfiguration();
    this.tokenExpiresIn = '';
    if (this.logoutWhenExpiredTimeout) {
      clearTimeout(this.logoutWhenExpiredTimeout);
    }

    CardApplication.initCardApplication();
    UserAccount.clearCardData();
    UserAccount.clearSelectedCustomerData();

    router.push(Paths.LOGIN);
  }

  public get loggedInAccountDisplayName() {
    return CustomerModule.hasLoggedInAccountCustomer ? formatName(CustomerModule.customer) : this.username;
  }

  public get tokenExpired() {
    return differenceInMilliseconds(this.tokenExpiresIn, new Date()) <= 0;
  }
}

export default getModule(Auth);
