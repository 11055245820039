import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { ShoppingCartVm, ShoppingCartItemVm } from '@/api';
import shoppingCartService from '@/services/api/shoppingCartService';
import isDefined, { isArrayEmpty } from '@common/utils/isDefined';
import UserAccount from '@/store/modules/userAccount';
import ToastModule from '@/store/modules/toast';
import { getEarliestTicketFareValidFrom } from '@/utils/ticketFare';
import { isBefore, startOfDay } from 'date-fns';
import { convertTimeToString, getRemainingTime } from '@/utils/dateTime';
import accountService from '@/services/api/accountService';

export const MIN_DAYS_BETWEEN_TICKET_FARE_VALID_FROM_AND_NOW = 3;

@Module({ dynamic: true, store, namespaced: true, name: 'shoppingCart' })
class ShoppingCart extends VuexModule {
  public items: ShoppingCartItemVm[] = [];
  public ticketFareParentIds: string[] = [];
  public totalPrice = 0;
  public remainingTime?: string = '00:00:00';
  public expirationTime: string | undefined = undefined;
  private lifeTimeTimer: NodeJS.Timeout | number | null = null;

  @Mutation
  public setShoppingCart(payload: ShoppingCartVm): void {
    this.items = payload.items;
    this.totalPrice = payload.totalPrice;

    this.ticketFareParentIds = [];
    for (const item of payload.items) {
      if (isDefined(item.payload?.ticketFareParentId)) {
        this.ticketFareParentIds.push(item.payload.ticketFareParentId);
      }
    }
  }

  @Mutation
  public setExpirationTime(expirationTime?: string) {
    this.expirationTime = expirationTime;
  }

  @Mutation
  public clearLifetimeTimer() {
    if (this.lifeTimeTimer) {
      clearInterval(this.lifeTimeTimer);
    }
  }

  @Mutation
  public updateRemainingTime(newRemainingTime?: string) {
    this.remainingTime = newRemainingTime;
  }

  @Mutation
  private setLifetimeTimer(timer: NodeJS.Timeout | number | null) {
    this.lifeTimeTimer = timer;
  }

  public get isEmpty() {
    return isArrayEmpty(this.items);
  }

  public get isCardApplicationInShoppingCartForSelectedUser() {
    return !!this.items.find(item => {
      return item.payload?.applicationId && item.payload?.originId === UserAccount.selectedCustomerData.id;
    });
  }

  public get isCardApplicationInShoppingCart() {
    return !!this.items.find(item => item.payload?.applicationId);
  }

  public get isReChargeWalletRequestInShoppingCart() {
    return !!this.items.find(item => item.payload?.eWalletId && item.payload?.cardNumber === UserAccount.cardNumber);
  }

  public get isTicketFareInShoppingCart() {
    return !!this.findTicketFareItem;
  }

  private get findTicketFareItem() {
    return this.items.find(
      item => item.payload?.ticketFareId && item.payload?.cardId === UserAccount.cardDetailData.id,
    );
  }

  public get isTicketFareTooSoon() {
    const earliestValidFrom = getEarliestTicketFareValidFrom();
    return this.items.some(item => {
      const ticketFareValidFrom = item.payload?.ticketFareValidFrom;
      if (!item.payload?.ticketFareId || !ticketFareValidFrom) {
        return false;
      }
      const validFrom = startOfDay(ticketFareValidFrom);
      return isBefore(validFrom, earliestValidFrom);
    });
  }

  public get getExpirationTime() {
    return this.expirationTime;
  }

  public get;

  @Action
  public async updateShoppingCart() {
    const shoppingCart = await shoppingCartService.getShoppingCart();
    this.setShoppingCart(shoppingCart);
  }

  @Action
  public async setCartLifetimeTimer(): Promise<void> {
    if (this.getExpirationTime) {
      const expirationTime = new Date(this.getExpirationTime).getTime();
      const dateNowForCheck = new Date().getTime();
      const remainingTimeForCheck = expirationTime - dateNowForCheck;

      if (remainingTimeForCheck <= 0) {
        await this.nullLifetimeAndClearCart();
        ToastModule.error({ message: 'Vypršel časový limit košíku. Všechny položky byly odebrány.' });
        return;
      }

      if (this.lifeTimeTimer) {
        this.clearLifetimeTimer();
      }

      const lifeTimeTimer = setInterval(() => {
        const remainingTime = getRemainingTime(expirationTime);

        if (remainingTime <= 0) {
          this.nullLifetimeAndClearCart();
          ToastModule.error({ message: 'Vypršel časový limit košíku. Všechny položky byly odebrány.' });
        } else {
          this.updateRemainingTime(convertTimeToString(remainingTime));
        }
      }, 1000);

      this.setLifetimeTimer(lifeTimeTimer);
    }
  }

  @Action
  public async nullLifetimeAndClearCart() {
    await this.nullCartLifetime();
    await shoppingCartService.removeAllItems();
    await this.updateShoppingCart();
  }

  @Action
  public async nullCartLifetime() {
    this.clearLifetimeTimer();
    this.updateRemainingTime('00:00:00');
    await accountService.nullCustomerAccountCartLifeTime();
  }

  @Action
  public updateLifetimeTimer() {
    this.clearLifetimeTimer();
    this.setCartLifetimeTimer();
  }

  @Action
  public async isCartExpired() {
    const cartLifetimeResponse = await accountService.getCustomerAccountCartLifetime();

    if (!cartLifetimeResponse.data.cartLifetime) return true;
    else if (cartLifetimeResponse.data.cartLifetime) {
      const cartLifetime = new Date(cartLifetimeResponse.data.cartLifetime).getTime();
      const utcDateNow = new Date().getTime();

      return cartLifetime - utcDateNow <= 0;
    }

    return false;
  }

  @Action
  public async getAndUpdateCartExpirationTime() {
    const response = await accountService.getCustomerAccountCartLifetime();

    this.setExpirationTime(response.data.cartLifetime);
  }
}

export default getModule(ShoppingCart);
