<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.display.mobile"
    max-width="850"
    scrollable
    persistent
    data-cy="e-wallet-dialog"
  >
    <v-card>
      <v-card-title class="text-h5 d-flex pl-3 bg-systemPrimary dialog-title">
        <v-icon size="large" start color="white" icon="$electronicWalletIcon" />
        <div class="align-self-center">{{ t('title') }}</div>
      </v-card-title>
      <v-card-text class="card-text" style="max-height: 800px">
        <v-form ref="form" validate-on="lazy" :data-cy="cy('form')" @submit.prevent>
          <v-row :class="{ 'mx-1': !$vuetify.display.mobile }">
            <v-col cols="12">
              <v-alert border="top" type="info" elevation="2" class="pt-6 mb-0" variant="text">
                {{ t('transactionDisplayInfo') }}
              </v-alert>
            </v-col>
          </v-row>
          <CustomerDetailForDialog :customer-detail-data="loadedCardDetailData" />
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 4" :class="$vuetify.display.mobile ? 'pl-4' : 'pl-7'">
              <v-text-field
                v-model="eWalletTransaction.price"
                :rules="validations.price"
                :label="t('price')"
                :no-data-text="$t('common.noData')"
                :data-cy="cy('price')"
                :suffix="$t('common.currency')"
                type="number"
                :min="minPrice"
                variant="underlined"
                @keypress="systemConfigurationModule.currency.Validator"
                @keyup="countNewAmount"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pl-7">
              <span>{{ t('newAmount') }}</span>
              <span class="ml-1 mr-1" :data-cy="cy('newAmount')">
                {{ filters.toPrice(newAmount.toString()) }}
              </span>
              <span>{{ $t('common.currency') }}</span>
              <div v-if="maxAmountErrorMessage !== ''" class="max-amount-error-message v-messages__message mt-0">
                {{ maxAmountErrorMessage }}
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer v-if="!$vuetify.display.mobile" />
        <div :class="buttonsClass">
          <v-btn
            color="buttonWarning"
            :data-cy="cy('issue-card-cancel-button')"
            :class="$vuetify.display.mobile ? 'my-2' : ''"
            variant="elevated"
            @click="closeDialog"
          >
            <template #prepend>
              <v-icon>mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.close') }}
          </v-btn>
          <ConfirmButton
            v-if="!$vuetify.display.mobile"
            dark
            :manual-loading="true"
            :confirm="confirmDialogOperation"
            :text="$t('buttons.confirmAndClose')"
            icon="mdi-check"
            :data-cy="cy('confirm-button')"
            :class="$vuetify.display.mobile ? '' : 'mx-2'"
          />
          <ConfirmButton
            dark
            :manual-loading="true"
            :confirm="confirmDialogOperationAndGoToShoppingCart"
            :text="confirmAndRedirectText"
            icon="mdi-cart-arrow-down"
            :data-cy="cy('confirm-button-and-redirect-shopping-cart')"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Ref } from 'vue-facing-decorator';
import { AddEWalletTransactionCommand, CustomerCardDetailVm, ElectronicWalletDto, GlobalSettingDto } from '@/api';
import CustomerDetailForDialog from '@/components/userAccount/dialogs/CustomerDetailForDialog.vue';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { maxLengthValidator, maxValue, minValue, requiredValidator } from '@/utils/validators';
import cloneDeep from 'lodash/cloneDeep';
import { FormInterface } from '@/components/common/FormInterface';
import globalSettingService from '@/services/api/globalSettingService';
import { GlobalSetting } from '@/constants/GlobalSettting';
import ComponentBase from '@/components/common/ComponentBase';
import Paths from '@/constants/Paths';
import shoppingCartService from '@/services/api/shoppingCartService';
import ShoppingCartModule from '@/store/modules/shoppingCart';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import ToastModule from '@/store/modules/toast';
import { getApiError } from '@/utils/toast';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';

@Component({
  components: {
    CustomerDetailForDialog,
    ConfirmButton,
  },
})
export default class EWalletDialog extends ComponentBase {
  @Prop({ required: true, type: Object })
  readonly loadedCardDetailData!: CustomerCardDetailVm;

  @Ref()
  readonly form!: FormInterface;

  eWalletTransaction = {
    operation: AddEWalletTransactionCommand.operation.RECHARGE,
  } as AddEWalletTransactionCommand;

  show = false;
  minPrice = 1;
  validations = {
    price: [(v: string) => requiredValidator(v)],
    note: [(v: string) => maxLengthValidator(v, 255)],
  };
  newAmount = 0;
  globalSettingList: GlobalSettingDto[] = [];
  maxAmountErrorMessage = '';
  shoppingCartModule = ShoppingCartModule;
  systemConfigurationModule = SystemConfigurationModule;

  eWallet = {} as ElectronicWalletDto;

  dataCy = 'userAccount-eWallet';
  i18nGroupKey = 'userAccountPage.customerDetail.eWallet';

  get confirmAndRedirectText() {
    const i18nKey = this.$vuetify.display.mobile ? 'addToShoppingCart' : 'confirmAndGoToShoppingCart';
    return this.$t(`buttons.${i18nKey}`);
  }

  get buttonsClass() {
    return this.$vuetify.display.mobile ? 'd-flex flex-column ml-auto' : '';
  }

  async created() {
    try {
      this.globalSettingList = await globalSettingService.getGlobalSetting();
    } catch (e) {
      ToastModule.error({ message: this.t('globalSettingLoadingFailed') });
    }
  }

  async openDialog() {
    this.eWallet = cloneDeep(this.loadedCardDetailData.electronicWallet!);
    this.eWalletTransaction.eWalletId = this.eWallet.id;
    this.newAmount = this.eWallet.currentAmount || 0;
    this.validations.price = [(v: string) => requiredValidator(v), (v: string) => minValue(v, this.minPrice)];
    this.show = true;
  }

  closeDialog() {
    this.resetForm();
  }

  countNewAmount() {
    if (this.eWalletTransaction.operation && this.eWalletTransaction.price) {
      this.newAmount = (this.eWallet.currentAmount || 0) + this.toPrice(+this.eWalletTransaction.price);
    } else {
      this.newAmount = this.eWallet.currentAmount || 0;
    }

    const maxAmountSetting = this.getMaxAmountSetting();
    if (maxAmountSetting && this.newAmount > maxAmountSetting.value) {
      this.maxAmountErrorMessage = this.t('maxAmount', {
        max: this.filters.toPrice((maxAmountSetting.value || '').toString()),
      });
    } else {
      this.maxAmountErrorMessage = '';
    }

    const maxRechargeSetting = this.getMaxRechargeSetting();
    if (maxRechargeSetting) {
      this.validations.price.push((v: string) => maxValue(v, this.fromPriceToDecimal(maxRechargeSetting.value)));
    }

    const minRechargeSetting = this.getMinRechargeSetting();

    if (minRechargeSetting) {
      this.validations.price.push((v: string) => minValue(v, this.fromPriceToDecimal(minRechargeSetting.value)));
    }
  }

  fromPriceToDecimal(price: number) {
    return price / 100;
  }

  toPrice(value: number) {
    return value * 100;
  }

  getMaxRechargeSetting() {
    return this.globalSettingList.find(
      (setting: GlobalSettingDto) => setting.name === GlobalSetting.EWalletMaxRecharge,
    );
  }

  getMinRechargeSetting() {
    return this.globalSettingList.find(
      (setting: GlobalSettingDto) => setting.name === GlobalSetting.EWalletMinRecharge,
    );
  }

  getMaxAmountSetting() {
    return this.globalSettingList.find((setting: GlobalSettingDto) => setting.name === GlobalSetting.EWalletMaxAmount);
  }

  async confirmDialogOperation(goToShoppingCart = false) {
    try {
      if ((await this.form.validate()).valid) {
        const eWalletTransactionCommand = {
          ...this.eWalletTransaction,
          previousBalance: this.loadedCardDetailData.electronicWallet!.currentAmount || 0,
          price: this.toPrice(+this.eWalletTransaction.price),
        };

        await shoppingCartService.createEWalletTransaction(eWalletTransactionCommand);
        await this.shoppingCartModule.updateShoppingCart();
        await this.shoppingCartModule.getAndUpdateCartExpirationTime();
        this.shoppingCartModule.setCartLifetimeTimer();

        if (goToShoppingCart) {
          this.$router.push(Paths.SHOPPING_CART);
        }

        this.closeDialog();
        ToastModule.success({
          message: this.$t(`${this.i18nGroupKey}.saveSuccessful`),
        });
      }
    } catch (e) {
      ToastModule.error({ message: getApiError(e as ApiErrorInterface, this.i18nGroupKey) });
    }
  }

  confirmDialogOperationAndGoToShoppingCart() {
    this.confirmDialogOperation(true);
  }

  resetForm() {
    this.eWalletTransaction = {
      operation: AddEWalletTransactionCommand.operation.RECHARGE,
    } as AddEWalletTransactionCommand;
    this.newAmount = 0;
    this.form.resetValidation();
    this.show = false;
  }
}
</script>

<style scoped lang="scss">
.headline {
  color: white;
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}

.max-amount-error-message {
  color: #ff5252;
  font-size: 12px;
  margin-top: 10px;
}
</style>
